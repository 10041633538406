import { Button, Divider } from 'antd';
import { find, isEmpty } from 'lodash';
import IsViewerWrapper from 'components/core/IsViewerWrapper';
import { useAppSelector } from 'hooks/useReduxHook';
import { ProjectStagesList, ProposedTimelineStatus } from 'store/projects/types';
import { UserPermission } from 'store/users/types';
import { AOIDetail } from 'store/aoi/types';
import { LatLongNumberType } from 'types/aoi.types';
import LulcProgressStats from './LulcProgressStats';
// import InitiateLayerButton from './InitiateLayerButton';
import ConfirmBeeLineButton from './ConfirmBeeLineButton';
import ProcuringSatelliteImageryText from './ProcuringSatelliteImageryText';

interface MemberActionButtonsType {
  isAOIGenerationNotStarted: boolean;
  isLulcGenInprogress: boolean;
  isAOIConfirmApproved: boolean;
  isBeelineBtnDisable: boolean;
  isSatelliteInprogress: boolean;
  isSatelliteProcurementInProgress: boolean;
  isHighResLULCInProgress: boolean;
  currentStep: number;
  aoiLists: AOIDetail[] | [];
  latlngA: LatLongNumberType | null;
  latlngB: LatLongNumberType | null;
  selectedAOIData: AOIDetail | null;
  editedAOIData: AOIDetail | null;
  handleNextStep: () => void;
  handleInitiateLayerGeneration: () => void;
  handleConfirmBeeLine: () => void;
  handleSentForApprovalAOI: () => void;
}

const MemberActionButtons = ({
  isLulcGenInprogress,
  isAOIConfirmApproved,
  isBeelineBtnDisable,
  isSatelliteInprogress,
  isAOIGenerationNotStarted,
  isSatelliteProcurementInProgress,
  isHighResLULCInProgress,
  currentStep,
  aoiLists,
  latlngA,
  latlngB,
  selectedAOIData,
  editedAOIData,
  handleNextStep,
  // eslint-disable-next-line
  handleInitiateLayerGeneration,
  handleConfirmBeeLine,
  handleSentForApprovalAOI
}: MemberActionButtonsType) => {
  const { selectedProject } = useAppSelector((state) => state.projects);
  const isSatelliteProcurementCompleted = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.SatelliteProcurement,
      status: ProposedTimelineStatus.Completed
    })
  );
  const isAoiGenerationInprogress = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.AOIGeneration,
      status: ProposedTimelineStatus.InProgress
    })
  );

  return (
    <>
      {!isEmpty(aoiLists) &&
        currentStep === 1 &&
        !isSatelliteInprogress &&
        !isSatelliteProcurementCompleted && (
          <Divider
            style={{
              width: '90%',
              minWidth: '90%',
              margin: '12px 12px 6px 12px',
              justifyContent: 'center',
              alignItems: 'center',
              borderColor: '#C7C7CC'
            }}
          />
        )}
      <div className="workspace-final-btn">
        {currentStep === 0 && !isEmpty(selectedProject?.start_point) && (
          <Button type="primary" ghost onClick={() => handleNextStep()}>
            Next
          </Button>
        )}
        <ConfirmBeeLineButton
          currentStep={currentStep}
          latlngA={latlngA}
          latlngB={latlngB}
          isBeelineBtnDisable={isBeelineBtnDisable}
          handleConfirmBeeLine={handleConfirmBeeLine}
        />
        {/* <InitiateLayerButton
          currentStep={currentStep}
          isLulcGenInprogress={isLulcGenInprogress}
          handleInitiateLayerGeneration={handleInitiateLayerGeneration}
        /> */}
        {!isAoiGenerationInprogress && (
          <LulcProgressStats
            currentStep={currentStep}
            isLulcGenInprogress={isLulcGenInprogress}
            isAOIGenerationNotStarted={isAOIGenerationNotStarted}
          />
        )}
        {currentStep === 1 &&
          selectedProject?.user_permission === UserPermission.Member &&
          !isAOIConfirmApproved &&
          isEmpty(editedAOIData) &&
          !isEmpty(aoiLists) && (
            <IsViewerWrapper>
              <Button
                disabled={!selectedAOIData || selectedAOIData?.status === 'Declined'}
                type="primary"
                onClick={handleSentForApprovalAOI}>
                Send for Approval
              </Button>
            </IsViewerWrapper>
          )}
        <ProcuringSatelliteImageryText
          currentStep={currentStep}
          isHighResLULCInProgress={isHighResLULCInProgress}
          isSatelliteProcurementInProgress={isSatelliteProcurementInProgress}
        />
      </div>
    </>
  );
};

export default MemberActionButtons;
