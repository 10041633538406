import React, { useState } from 'react';
import { find, isEmpty, orderBy } from 'lodash';
import { Button, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { BsDot } from 'react-icons/bs';
import { primary, lightred, lightDarkGrey } from 'constants/theme.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import IsViewerWrapper from 'components/core/IsViewerWrapper';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { getMember } from 'store/projects';
import {
  MemberT,
  ProjectStagesList,
  ProjectState,
  ProposedTimelineStatus
} from 'store/projects/types';
import { AOIDetail, AoiState, AOIStausType, GenerateAoiPayload } from 'store/aoi/types';
import { RouteDetail } from 'store/route/types';
import { RepositoryFile } from 'store/repositoryfile/types';
import { activeLowresLayersFile } from 'store/repositoryfile';
import { getProject } from 'store/projects/actions';
import { SkipAOIgenerate } from 'store/aoi/actions';
import { getAsyncStorageValue } from 'utils/localStorage';
import AoiItemCard from './AoiItemCard';
import AOIRouteLoadingCard from './AOIRouteLoadingCard';
import AOIRouteInvalidCard from './AOIRouteInvalidCard';

const { Text } = Typography;

interface AOIListPropsType {
  isShowGenerateButton: boolean;
  isDisableGenerateButton: boolean;
  isRequestingGenerateAOI: boolean;
  aoiLists: AOIDetail[] | [];
  selectedAoiIds: string[];
  editedAOIData: AOIDetail | null;
  selectedAOIData: AOIDetail | null;
  AOIRasterBufferData: AOIDetail | null;
  handleGenerateAoiData: () => void;
  handleSelectedAOI: (data: AOIDetail | null) => void;
  handleShowHideSelectedAoiIds: (fileId: string) => void;
  handleEditAOI: (data: AOIDetail | null) => void;
  handleShowHideRasterBuffer: (data: AOIDetail | RouteDetail | null) => void;
}

const AOIList = ({
  isDisableGenerateButton,
  isShowGenerateButton,
  isRequestingGenerateAOI,
  aoiLists,
  editedAOIData,
  selectedAOIData,
  selectedAoiIds,
  AOIRasterBufferData,
  handleGenerateAoiData,
  handleShowHideSelectedAoiIds,
  handleEditAOI,
  handleSelectedAOI,
  handleShowHideRasterBuffer
}: AOIListPropsType) => {
  const dispatch = useAppDispatch();
  const { isRequestingSkipGenerateAOI }: AoiState = useAppSelector((state) => state.aoi);
  const activeLowLayerFiles: RepositoryFile[] | [] = useAppSelector(activeLowresLayersFile);
  const { selectedProject }: ProjectState = useAppSelector((state) => state.projects);
  const [isViewCommentModal, setViewCommentModal] = useState<boolean>(false);
  const [isSkipAOIModal, setSkipAOIModal] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const isApprovedSatelliteConfirm = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.SatelliteConfirm,
      status: ProposedTimelineStatus.Approved
    })
  );

  const isAOIConfirmApproved = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.AOIConfirmation,
      status: ProposedTimelineStatus.Approved
    })
  );

  const handleChangeAOI = (id: string) => {
    const selectedAoi = aoiLists.find((aoi: any) => aoi.id === id);
    handleSelectedAOI(selectedAoi || null);
    if (id) handleShowHideSelectedAoiIds(id);
  };

  const handleConfirmSkipAOI = () => {
    if (!isEmpty(activeLowLayerFiles)) {
      const payload: GenerateAoiPayload[] | [] = activeLowLayerFiles.map((file: RepositoryFile) => {
        return {
          file_id: Number(file.id),
          weight: file.weight ? Number(file.weight) : 0,
          buffer: Number(file.buffer),
          file_name: file.file_name,
          file_cat: file.category
        };
      });
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          SkipAOIgenerate(
            { vectors: payload, skip_generation: true },
            selectedProject?.id!,
            token,
            () => {
              setSkipAOIModal(false);
              dispatch(getProject(selectedProject?.id!, token));
            }
          )
        );
      });
    } else {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          SkipAOIgenerate(
            { vectors: [], skip_generation: true },
            selectedProject?.id!,
            token,
            () => {
              setSkipAOIModal(false);
              dispatch(getProject(selectedProject?.id!, token));
            }
          )
        );
      });
    }
  };

  const handleSkipAoiData = () => {
    setSkipAOIModal(true);
  };

  const handleCancelSkipAOI = () => {
    setSkipAOIModal(false);
  };

  const wmApprovedAOI: AOIDetail | undefined = find(aoiLists, ['status', AOIStausType.wmapproved]);
  const isDisabledAOIButtons = isDisableGenerateButton || isAOIConfirmApproved;

  return (
    <>
      <div className="aoi-main">
        {isShowGenerateButton && (
          <div className="generate-aoi-btn-div">
            <IsViewerWrapper>
              <Button
                className="generate-aoi-btn"
                type="primary"
                data-testid="generateaoi"
                ghost
                disabled={isDisabledAOIButtons}
                onClick={handleGenerateAoiData}
                loading={isRequestingGenerateAOI}>
                Generate AOI
              </Button>
            </IsViewerWrapper>
            <Button
              style={{
                marginTop: 5,
                color: isDisabledAOIButtons ? lightDarkGrey : primary,
                textDecorationLine: 'underline'
              }}
              className="generate-aoi-btn skip-aoi-btn"
              type="text"
              data-testid="skipaoi"
              disabled={isDisabledAOIButtons}
              onClick={handleSkipAoiData}
              loading={isRequestingSkipGenerateAOI}>
              Skip AOI generation
            </Button>
          </div>
        )}
        <div className="aoi-list" style={{ height: 'calc(100% - 69px)' }}>
          {!isEmpty(aoiLists) && (
            <Text className="generate-title" key="aoi-list">
              Generated AOI<Text>*</Text>
            </Text>
          )}
          <div className="aoi-list-items" key="aoi-list-comp">
            {
              // eslint-disable-next-line
              !editedAOIData ? (
                <>
                  {wmApprovedAOI && (
                    <AoiItemCard
                      isEditAOI={false}
                      aoiDetail={wmApprovedAOI}
                      selectedAoiIds={selectedAoiIds}
                      selectedAoi={selectedAOIData}
                      handleShowHideSelectedAoiIds={handleShowHideSelectedAoiIds}
                      handleEditAOI={handleEditAOI}
                      handleChangeAOI={handleChangeAOI}
                      AOIRasterBufferData={AOIRasterBufferData}
                      handleShowHideRasterBuffer={handleShowHideRasterBuffer}
                    />
                  )}
                  {wmApprovedAOI && wmApprovedAOI.updated_by && wmApprovedAOI.updated_at !== null && (
                    <div className="approve-dwnld">
                      <BsDot size={16} color={primary} />
                      <Text className="approved">{`Approved by ${
                        getMember(wmApprovedAOI.updated_by!).label
                      } for High
                             Res Satellite Image Purchase`}</Text>
                    </div>
                  )}
                  {orderBy(aoiLists, ['requested_at'], ['desc'])
                    .filter((a) => a.status !== AOIStausType.wmapproved)
                    .map((item: AOIDetail, i: number) => {
                      const updatedbyUser: MemberT | null = item.updated_by
                        ? getMember(item.updated_by)
                        : null;

                      return (
                        <>
                          {item.status !== AOIStausType.requested &&
                            item.status !== AOIStausType.failed &&
                            item.status !== AOIStausType.invalid && (
                              <AoiItemCard
                                key={item.id}
                                isEditAOI={false}
                                aoiDetail={item}
                                selectedAoiIds={selectedAoiIds}
                                selectedAoi={selectedAOIData}
                                handleShowHideSelectedAoiIds={handleShowHideSelectedAoiIds}
                                handleEditAOI={handleEditAOI}
                                handleChangeAOI={handleChangeAOI}
                                AOIRasterBufferData={AOIRasterBufferData}
                                handleShowHideRasterBuffer={handleShowHideRasterBuffer}
                              />
                            )}
                          {(item.status === AOIStausType.requested ||
                            item.status === AOIStausType.failed) && (
                            <AOIRouteLoadingCard
                              title="AOI"
                              key={`${item.id}-${item.comment}-${i}`}
                              aoiDetail={item}
                            />
                          )}
                          {item.status === AOIStausType.invalid && (
                            <AOIRouteInvalidCard
                              title="AOI"
                              key={`${item.id}-${item.comment}-${i}`}
                              aoiRouteDetail={item}
                              AOIRouteRasterData={AOIRasterBufferData}
                              selectedAoiRouteId={selectedAOIData?.id ? selectedAOIData.id : null}
                              // @ts-ignore
                              handleEditAOI={handleEditAOI}
                              handleEditRoute={() => {}}
                              handleChangeAOI={handleChangeAOI}
                              handleChangeRoute={() => {}}
                              handleShowHideRaster={handleShowHideRasterBuffer}
                            />
                          )}
                          {updatedbyUser &&
                            item.updated_at !== null &&
                            item.status === AOIStausType.sentforapproval && (
                              <div key={item.status} className="approve-dwnld">
                                <BsDot size={16} color={primary} />
                                <Text className="approved">
                                  Sent by {updatedbyUser.label} for approval
                                </Text>
                              </div>
                            )}
                          {updatedbyUser &&
                            item.updated_at !== null &&
                            !isApprovedSatelliteConfirm &&
                            item.status === AOIStausType.pmapproved && (
                              <div
                                key={`${item.status}-${item.status}-${i + 20}`}
                                className="approve-dwnld"
                                style={{ justifyContent: 'flex-start' }}>
                                <BsDot size={16} color={primary} />
                                <Text className="approved">{`Approved by ${updatedbyUser.label}`}</Text>
                              </div>
                            )}
                          {updatedbyUser &&
                            item.updated_at !== null &&
                            item.status === AOIStausType.declined && (
                              <div key={`${item.status}-${i + 1}`} className="declined-aoi">
                                <div>
                                  <BsDot size={16} color={lightred} />
                                  <Text className="declined">{`Declined by ${updatedbyUser.label}`}</Text>
                                </div>
                                <div
                                  onClick={() => {
                                    setViewCommentModal(true);
                                    setComment(item.comment || '');
                                  }}>
                                  View comments
                                </div>
                              </div>
                            )}
                          {item.status === AOIStausType.failed && (
                            <div
                              key={`${item.status}-${i + 10}`}
                              className="approve-dwnld"
                              style={{ justifyContent: 'flex-start' }}>
                              <BsDot size={16} color={lightred} />
                              <Text className="declined" style={{ color: lightred, width: '80%' }}>
                                AOI Generation Failed
                              </Text>
                            </div>
                          )}
                        </>
                      );
                    })}
                </>
              ) : editedAOIData.status === AOIStausType.invalid ? (
                <AOIRouteInvalidCard
                  isEdit
                  title="AOI"
                  aoiRouteDetail={editedAOIData}
                  AOIRouteRasterData={AOIRasterBufferData}
                  selectedAoiRouteId={selectedAOIData?.id ? selectedAOIData.id : null}
                  // @ts-ignore
                  handleEditAOI={handleEditAOI}
                  handleEditRoute={() => {}}
                  handleChangeAOI={handleChangeAOI}
                  handleChangeRoute={() => {}}
                  handleShowHideRaster={handleShowHideRasterBuffer}
                />
              ) : (
                <AoiItemCard
                  isEditAOI
                  aoiDetail={editedAOIData}
                  selectedAoiIds={selectedAoiIds}
                  selectedAoi={selectedAOIData}
                  handleShowHideSelectedAoiIds={handleShowHideSelectedAoiIds}
                  handleEditAOI={handleEditAOI}
                  AOIRasterBufferData={AOIRasterBufferData}
                  handleShowHideRasterBuffer={handleShowHideRasterBuffer}
                  handleChangeAOI={handleChangeAOI}
                />
              )
            }
          </div>
        </div>
      </div>
      <ConfirmModal
        isOpen={isViewCommentModal}
        width={523}
        isClosable={false}
        isCancelButton={false}
        okbuttonText="Okay"
        title=""
        description="Decline AOI Comment"
        subDescription={comment}
        handleClickOkButton={() => setViewCommentModal(false)}
        handleClickCancelButton={() => setViewCommentModal(false)}
      />
      <ConfirmModal
        isOpen={isSkipAOIModal}
        width={523}
        isClosable={false}
        isCancelButton
        isLoading={isRequestingSkipGenerateAOI}
        okbuttonText="Skip"
        cancelbuttonText="No"
        title="Skip AOI generation"
        description="Are you sure you want to Skip AOI generation?"
        handleClickOkButton={handleConfirmSkipAOI}
        handleClickCancelButton={handleCancelSkipAOI}
      />
    </>
  );
};

export default AOIList;
