import { ACCESS_TOKEN, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { toast } from 'react-toastify';
import { getProject } from 'store/projects/actions';
import { PostResponse, Response } from 'types/common.types';
import { getAsyncStorageValue } from 'utils/localStorage';
import {
  APPROVE_DECLINE_AOI_URL,
  BEELINE_COORDS_URL,
  EDIT_AOI_URL,
  GENERATE_AOI_URL,
  GET_AOI_URL,
  HIGH_RES_URL,
  LULC_LAYER_GENERATION_URL,
  SKIP_GENERATE_AOI_URL
} from 'utils/apiUrls';
import { apiCall } from 'utils/connect';
import { AppDispatch } from '..';
import {
  BeeLinePayload,
  GenerateAoiPayload,
  AOIDetailType,
  ApproveDeclineAoiPayload,
  EditAoiPayload,
  HiResPayload
} from './types';
import {
  setRequestingBeelinePointsCoords,
  setAoiLists,
  setRequestingGenerateLULCLayer,
  setRequestingGenerateAOI,
  setRequestingAoiFiles,
  setRequestingAppproveDeclineAOI,
  setRequestingEditAOI,
  setRequestHighRes,
  setRequestingSkipGenerateAOI
} from '.';

// new api
export const addBeelinePointsCoords =
  (
    payload: BeeLinePayload,
    projectId: number,
    token: string,
    callback?: (status: boolean) => void
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingBeelinePointsCoords(true));

      const onSuccess = (response: Response<any>) => {
        dispatch(setRequestingBeelinePointsCoords(false));
        if (response.status) {
          toast.success(response?.message);
          dispatch(getProject(projectId, token));
        }
        if (callback) callback(response.data);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addBeelinePointsCoords(payload, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingBeelinePointsCoords(false));
        }
      };

      apiCall('POST', BEELINE_COORDS_URL(projectId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingBeelinePointsCoords(false));
      toast.error(error.message);
    }
  };

// get AOI files data
export const getAOILists =
  (projectId: number, token: string, callback?: Function) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingAoiFiles(true));

      // eslint-disable-next-line
      const onSuccess = (response: Response<AOIDetailType[]>) => {
        dispatch(setAoiLists(response.data));
        dispatch(setRequestingAoiFiles(false));
        if (callback) callback(response.data);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getAOILists(projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingAoiFiles(false));
        }
      };

      apiCall('GET', GET_AOI_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingAoiFiles(false));
      toast.error(error.message);
    }
  };

// generate AOI api
export const generateAOI =
  (
    payload: { vectors: GenerateAoiPayload[]; skip_generation?: true },
    projectId: number,
    token: string,
    callback?: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingGenerateAOI(true));

      const onSuccess = (response: Response<PostResponse>) => {
        dispatch(getAOILists(projectId, token));
        dispatch(setRequestingGenerateAOI(false));
        toast.success(response.message);
        if (callback) callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(generateAOI(payload, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingGenerateAOI(false));
        }
      };

      apiCall('POST', GENERATE_AOI_URL(projectId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingGenerateAOI(false));
      toast.error(error.message);
    }
  };

// Skip AOI generate api
export const SkipAOIgenerate =
  (
    payload: { vectors: GenerateAoiPayload[]; skip_generation?: true },
    projectId: number,
    token: string,
    callback?: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingSkipGenerateAOI(true));

      const onSuccess = (response: Response<PostResponse>) => {
        dispatch(getAOILists(projectId, token));
        dispatch(setRequestingSkipGenerateAOI(false));
        toast.success(response.message);
        if (callback) callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(SkipAOIgenerate(payload, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingSkipGenerateAOI(false));
        }
      };

      apiCall('POST', SKIP_GENERATE_AOI_URL(projectId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingSkipGenerateAOI(false));
      toast.error(error.message);
    }
  };

// edit AOI api
export const editAOI =
  (payload: EditAoiPayload, projectId: number, aoiId: string, token: string, callback: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingEditAOI(true));

      const onSuccess = (response: Response<PostResponse>) => {
        dispatch(getAOILists(projectId, token));
        dispatch(setRequestingEditAOI(false));
        toast.success(response.message);
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(editAOI(payload, projectId, aoiId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingEditAOI(false));
        }
      };

      apiCall('POST', EDIT_AOI_URL(projectId, aoiId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingEditAOI(false));
      toast.error(error.message);
    }
  };

export const appproveDeclineAOI =
  (
    payload: ApproveDeclineAoiPayload,
    projectId: number,
    aoiID: string,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingAppproveDeclineAOI(true));

      const onSuccess = (response: Response<PostResponse>) => {
        dispatch(getAOILists(projectId, token));
        dispatch(setRequestingAppproveDeclineAOI(false));
        callback();
        toast.success(response.message);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(appproveDeclineAOI(payload, projectId, aoiID, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingAppproveDeclineAOI(false));
        }
      };

      apiCall(
        'POST',
        APPROVE_DECLINE_AOI_URL(projectId, aoiID),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingAppproveDeclineAOI(false));
      toast.error(error.message);
    }
  };

export const initialGenerateLULCLayer =
  (projectId: number, token: string, callback: (res: any) => void) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingGenerateLULCLayer(true));

      const onSuccess = (response: Response<PostResponse>) => {
        dispatch(setRequestingGenerateLULCLayer(false));
        callback(response);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(initialGenerateLULCLayer(projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingGenerateLULCLayer(false));
        }
      };

      apiCall('GET', LULC_LAYER_GENERATION_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingGenerateLULCLayer(false));
      toast.error(error.message);
    }
  };

export const initiateRequestHighRes =
  (payload: HiResPayload, token: string, callback: (res: any) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestHighRes(true));

      const onSuccess = (response: Response<PostResponse>) => {
        dispatch(setRequestHighRes(false));
        callback(response);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(initiateRequestHighRes(payload, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestHighRes(false));
        }
      };

      apiCall('POST', HIGH_RES_URL, payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestHighRes(false));
      toast.error(error.message);
    }
  };
