import { Button, Modal, Typography } from 'antd';
import { isEmpty } from 'lodash';
import CloseIcon from 'assets/svg/CloseIcon';

const { Text } = Typography;
interface ConfirmModalProps {
  isOpen: boolean;
  isClosable: boolean;
  isCancelButton: boolean;
  title: string;
  description: string;
  handleClickOkButton: Function;
  handleClickCancelButton: Function;
  isLoading?: boolean;
  okbuttonText?: string;
  cancelbuttonText?: string;
  subDescription?: string | null;
  width?: number;
}

const ConfirmModal = ({
  isOpen,
  isClosable,
  isCancelButton,
  title,
  description,
  okbuttonText,
  cancelbuttonText,
  width,
  subDescription,
  isLoading,
  handleClickOkButton,
  handleClickCancelButton
}: ConfirmModalProps) => {
  return (
    <Modal
      title={title === '' ? null : title}
      className="approve-modal"
      width={width}
      closable={isClosable}
      open={isOpen}
      closeIcon={<CloseIcon onClick={() => handleClickCancelButton()} />}
      footer={null}
      centered>
      <div className="approve-modal-content">
        {!isEmpty(description) && (
          <Text className={subDescription ? 'modal-title subdes-yes' : 'modal-title'}>
            {description}
          </Text>
        )}
        {subDescription && <Text className="notify-content">{subDescription}</Text>}
        <div className={subDescription ? 'modal-content-btn no-cancel-btn' : 'modal-content-btn'}>
          <Button
            type="primary"
            loading={isLoading}
            className="confirm-btn"
            onClick={() => handleClickOkButton()}>
            {okbuttonText}
          </Button>
          {isCancelButton && (
            <Button
              type="ghost"
              disabled={isLoading}
              className="cancel-btn"
              onClick={() => handleClickCancelButton()}>
              {cancelbuttonText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  okbuttonText: 'Confirm',
  cancelbuttonText: 'Cancel',
  subDescription: null,
  isLoading: false,
  width: 523
};

export default ConfirmModal;
