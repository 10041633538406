import { Button, Divider } from 'antd';
import { find, isEmpty } from 'lodash';
import IsViewerWrapper from 'components/core/IsViewerWrapper';
import { useAppSelector } from 'hooks/useReduxHook';
import { AOIDetail, DisableAOIApprove } from 'store/aoi/types';
import { ProjectStagesList, ProposedTimelineStatus } from 'store/projects/types';
import { UserPermission } from 'store/users/types';
import { LatLongNumberType } from 'types/aoi.types';
import LulcProgressStats from './LulcProgressStats';
// import InitiateLayerButton from './InitiateLayerButton';
import ConfirmBeeLineButton from './ConfirmBeeLineButton';
import ProcuringSatelliteImageryText from './ProcuringSatelliteImageryText';

interface WMActionButtonsType {
  isLulcGenInprogress: boolean;
  isBeelineBtnDisable: boolean;
  isAOIGenerationNotStarted: boolean;
  isSatelliteInprogress: boolean;
  isSatelliteProcurementInProgress: boolean;
  isHighResLULCInProgress: boolean;
  isSatelliteConfirmApproved: boolean;
  currentStep: number;
  aoiLists: AOIDetail[] | [];
  latlngA: LatLongNumberType | null;
  latlngB: LatLongNumberType | null;
  selectedAOIData: AOIDetail | null;
  editedAOIData: AOIDetail | null;
  handleNextStep: () => void;
  handleInitiateLayerGeneration: () => void;
  handleConfirmBeeLine: () => void;
  handleApproveByWMOrPM: () => void;
  handleDeclineByWMOrPM: () => void;
}

const WMActionButtons = ({
  isLulcGenInprogress,
  isBeelineBtnDisable,
  isSatelliteInprogress,
  isSatelliteProcurementInProgress,
  isHighResLULCInProgress,
  isSatelliteConfirmApproved,
  isAOIGenerationNotStarted,
  currentStep,
  aoiLists,
  latlngA,
  latlngB,
  selectedAOIData,
  editedAOIData,
  handleNextStep,
  // eslint-disable-next-line
  handleInitiateLayerGeneration,
  handleConfirmBeeLine,
  handleApproveByWMOrPM,
  handleDeclineByWMOrPM
}: WMActionButtonsType) => {
  const { selectedProject } = useAppSelector((state) => state.projects);

  const isSatelliteProcurementCompleted = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.SatelliteProcurement,
      status: ProposedTimelineStatus.Completed
    })
  );
  const isAoiGenerationInprogress = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.AOIGeneration,
      status: ProposedTimelineStatus.InProgress
    })
  );

  return (
    <>
      {!isEmpty(aoiLists) &&
        currentStep === 1 &&
        !isSatelliteInprogress &&
        !isSatelliteProcurementCompleted && (
          <Divider
            style={{
              width: '90%',
              minWidth: '90%',
              margin: '12px 12px 6px 12px',
              justifyContent: 'center',
              alignItems: 'center',
              borderColor: '#C7C7CC'
            }}
          />
        )}
      <div className="workspace-final-btn">
        {currentStep === 0 && !isEmpty(selectedProject?.start_point) && (
          <Button type="primary" ghost onClick={() => handleNextStep()}>
            Next
          </Button>
        )}
        <ConfirmBeeLineButton
          currentStep={currentStep}
          latlngA={latlngA}
          latlngB={latlngB}
          isBeelineBtnDisable={isBeelineBtnDisable}
          handleConfirmBeeLine={handleConfirmBeeLine}
        />
        {/* <InitiateLayerButton
          currentStep={currentStep}
          isLulcGenInprogress={isLulcGenInprogress}
          handleInitiateLayerGeneration={handleInitiateLayerGeneration}
        /> */}
        {!isAoiGenerationInprogress && (
          <LulcProgressStats
            currentStep={currentStep}
            isLulcGenInprogress={isLulcGenInprogress}
            isAOIGenerationNotStarted={isAOIGenerationNotStarted}
          />
        )}
        {currentStep === 1 &&
          !isSatelliteConfirmApproved &&
          selectedProject?.user_permission === UserPermission.WorkspaceManager &&
          isEmpty(editedAOIData) &&
          !isEmpty(aoiLists) && (
            <>
              {currentStep === 1 && !isEmpty(aoiLists) && (
                <div
                  className="aoi-satellite-info"
                  style={{
                    color: !(
                      !selectedAOIData ||
                      !isEmpty(editedAOIData) ||
                      DisableAOIApprove.includes(selectedAOIData?.status)
                    )
                      ? '#316DB3'
                      : '#C7C7CC'
                  }}>
                  Approve AOI for Satellite Data Purchase
                </div>
              )}
              <div className="approve-decline-btn">
                <IsViewerWrapper>
                  <Button
                    type="primary"
                    disabled={
                      !selectedAOIData ||
                      !isEmpty(editedAOIData) ||
                      DisableAOIApprove.includes(selectedAOIData?.status)
                    }
                    onClick={handleApproveByWMOrPM}>
                    Approve
                  </Button>
                </IsViewerWrapper>
                <IsViewerWrapper>
                  <Button
                    type="primary"
                    ghost
                    disabled={
                      !selectedAOIData ||
                      !isEmpty(editedAOIData) ||
                      DisableAOIApprove.includes(selectedAOIData?.status)
                    }
                    onClick={handleDeclineByWMOrPM}>
                    Decline
                  </Button>
                </IsViewerWrapper>
              </div>
            </>
          )}
        <ProcuringSatelliteImageryText
          currentStep={currentStep}
          isHighResLULCInProgress={isHighResLULCInProgress}
          isSatelliteProcurementInProgress={isSatelliteProcurementInProgress}
        />
      </div>
    </>
  );
};

export default WMActionButtons;
